import { Button, Center, Text } from '@mantine/core';
import { IconTableShortcut } from '@tabler/icons-react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { SERVER_SAFE_API_URL } from '@/core/api';
import { NotFoundIcon } from '@/core/constants/icons.constants';
import { Paths } from '@/core/constants/routes.constants';
import ProductLayout from '@/core/layouts/product-layout/product-layout';
import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';

/**
 * NotFound
 *
 *
 *
 * @returns {React.Component} NotFound
 */
const NotFound = () => {
  const router = useRouter();
  const setFeatureFlags = useFeatureFlagsStore(
    (state) => state.actions.setFeatureFlags
  );

  useEffect(() => {
    // 404 page is a static page so it can't use "getStaticProps" to get the feature flags
    const fetchFlags = async () => {
      const response = await axios.get(
        `${SERVER_SAFE_API_URL}/healthcheck/config`
      );
      setFeatureFlags(response.data);
    };
    fetchFlags();
  }, []);

  return (
    <>
      <Center mt='3vh'>
        <NotFoundIcon />
      </Center>
      <Center mt='xl'>
        <Text size='xl'>Hm. We cannot seem to locate that project or run.</Text>
      </Center>
      <Center>
        <Text mr='xs'>Click below to go to the latest runs </Text>
      </Center>
      <Center mt='lg'>
        <Button
          leftSection={<IconTableShortcut size={16} />}
          size='sm'
          onClick={() => router.push({ pathname: Paths.ROOT })}
        >
          Go to Latest Runs
        </Button>
      </Center>
    </>
  );
};

NotFound.auth = true;
NotFound.getLayout = function getLayout(page: any) {
  return <ProductLayout>{page}</ProductLayout>;
};

export default NotFound;
